@font-face {
	font-family: 'suisseint';
	src: url("./assets/fonts/Suisse Int'l.ttf") format('woff');
}

@font-face {
	font-family: 'suisseint';
	src: url('./assets/fonts/SuisseIntl-Bold.woff') format('woff');
	font-weight: bold;
}

:root {
	--blue: #ff1e1e;
	--white: #ffffff;
}

html,
body {
	margin: 0px;
	padding: 0px;

	width: 100%;
	overflow-x: hidden;
	box-sizing: border-box;
}

body {
	font-family: 'suisseint';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
}

.header__background_light {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: linear-gradient(0deg, #b10000, #ff3b3b, #ffc3c3, #ffffff);
	overflow: hidden;

	z-index: -1;
}

.header__background_dark {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: linear-gradient(0deg, #000000, #1c0303, #000000);
	overflow: hidden;

	z-index: -1;
}

.header__background_light:after,
.header__background_dark:after {
	content: '';
	filter: invert(90%);
	background: url('https://www.transparenttextures.com/patterns/cubes.png');
	mix-blend-mode: difference;
	width: 800%;
	position: absolute;
	top: -400%;
	right: -400%;
	height: 800%;
	opacity: 1;
	animation-name: rotate;
	animation-duration: 888s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.header__background_dark:after {
	filter: invert(10%);
}

@-webkit-keyframes rotate {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}
@-o-keyframes rotate {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}
@keyframes rotate {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

.prevent-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

#root {
	align-items: center;

	margin-left: auto;
	margin-right: auto;
	max-width: 700px;

	min-width: 350px;
}

.enter-bg {
	text-align: center;
}

.mission-delivery-mode {
	padding: 15px;
}

#capture {
	width: auto;
	display: block !important;
}

:focus {
	outline: none;
}

@media print {
	#capture {
		height: 100dvh;
		margin: 0 !important;
		padding: 0 !important;
		overflow: hidden;
	}
	.no-print {
		display: none;
	}
}

.PhoneInput {
	text-align: left;
	padding: 4px !important;
}

.app-logged-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

* {
	box-sizing: border-box;
}

.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2em;
	color: #fff;
	font-weight: bold;
	text-align: center;
	z-index: 1;
}

.loader,
.loader:after {
	border-radius: 50%;
	width: 5em;
	height: 5em;
}
.loader {
	margin: auto auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(255, 255, 255, 0.2);
	border-right: 1.1em solid rgba(255, 255, 255, 0.2);
	border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
	border-left: 1.1em solid #ffffff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.invalid {
	color: crimson;
}

.small {
	font-size: small;
}

.medium {
	font-size: smaller;
}

a {
	text-decoration: none;

	font-size: medium;
}

.App {
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: '10px';
}

.App-logo {
	pointer-events: none;
}

.app-logged {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: '10px';
}

@media (prefers-reduced-motion: no-preference) {
}

.App-header {
	align-items: center;
	justify-content: center;
}

.App-header span {
	display: inline-block;
}

.App-link {
	color: firebrick;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.welcome-links {
	margin-top: 25px;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;
	font-size: calc(10px + 1vmin);
	gap: calc(6px + 2vmin);
}
.welcome-links strong {
	color: firebrick;
}

.welcome-links div {
	background-color: white;
	min-height: 220px;

	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 10px;

	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
	border: 3px solid white;
}

.welcome-links div:hover {
	border: 3px solid firebrick;
	color: firebrick;
	cursor: pointer;
}

.welcome-links b {
	font-weight: normal;
	text-decoration: underline;
}

.welcome-links p {
	font-size: 10px;
	color: #333;
}

.welcome-links div:hover + strong {
	color: white;
}

.icon {
	color: firebrick;
	font-size: 50px;

	justify-content: center;
	align-items: center;
}

.welcome-links a {
	text-decoration: none;
	color: #333;
	flex-basis: 45%;

	min-width: 120px;
}

.back {
	display: flex;
	position: fixed;
	top: 20px;
	left: 20px;
	font-size: 50px;

	border-radius: 20px;
}

.back:hover {
	color: black;
}

code {
	display: block;
	font-family: monospace;
	font-size: 1em;
	opacity: 0.4;

	color: #cbc5bc;
}
code b {
	color: #8c8c8c;
	text-transform: uppercase;
}

.tabs button {
	font-weight: bold;
	font-family: 'suisseint';
}

.special-label {
	background: linen !important;
}

h4 {
	text-align: left;
	padding-top: 10px;

	margin: 0px;
}

.profile-button {
	font-size: 30px;
	background: none;
	border: none;
	cursor: pointer;
	align-items: center;

	font-weight: normal;
}

.profile-button:hover {
	color: firebrick;
}

.loged-back {
	display: flex;
	font-size: 30px;

	align-items: center;
	width: 100%;
}

.loged-back:hover {
	color: rgb(0, 0, 0);
}

.app-logged-header-left {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
}

.app-logged-body {
	flex-direction: column;
	width: clamp(200px, 650px, 100%);
}

nav {
	display: flex;
	column-gap: 10px;
}

.pop {
	background-color: rgb(0, 0, 0);
	color: white;
	display: flex;
	height: 100px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	font-size: small;
	cursor: pointer;
	position: relative;
	padding: 10px;
}

.large-button {
	background-color: white;
	display: flex;
	height: 100px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	flex-grow: 1;
	cursor: pointer;
	font-weight: bold;
}

.large-button:hover {
	background-color: firebrick;
	color: white;
}

.card-container {
	border-radius: 10px;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.card-containerA {
	background-color: white;
	border-radius: 10px;
	display: flex;
	overflow-y: hidden;
	padding: 20px;
	flex-direction: row;
	gap: 10px;

	align-items: center;

	flex-wrap: wrap;
}

.card-container-left {
	overflow-y: hidden;
}

.mission-immediate {
	display: flex;
	border: 1px solid linen;
	border-radius: 10px;
	padding: 10px;
	margin: 10px 0px 10px 0px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.box-text {
	display: flex;

	margin: 10px;
}

.mission-location {
	display: flex;
	gap: 20px;
	justify-content: space-between;
}

.mission-location > div {
	flex-grow: 1;
}

.mission-from-to {
	display: flex;
	border: 1px solid linen;
	border-radius: 10px;
	padding: 10px;
	margin: 10px 0px 10px 0px;
	flex-direction: row;

	align-items: center;
	color: #8c8c8c;
	font-size: small;
}

.mission-from-to:hover {
	border: 1px solid firebrick;
	color: black;
	cursor: pointer;
}

.mission-requirements {
	display: flex;
	border: 1px solid linen;
	border-radius: 10px;
	padding: 10px;
	margin: 10px 0px 10px 0px;
}

.mission-requirements > div {
	flex-grow: 1;
	color: #8c8c8c;
}

.mission-requirements label {
	display: flex;
}

.uploader {
	display: flex;
	padding: 10px;
	margin: 10px 0px 10px 0px;
	border-radius: 10px;
}

.uploader .img {
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 10px;
}

.closer {
	top: -10px;
	right: -10px;
	padding: 5px 10px;
	border-radius: 50%;
	background: red;
	color: white;
}

.statements-list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-size: small;
	padding: 10px;
}

.statement-list-item {
	display: flex;
	flex-direction: row;

	width: 100%;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e0e0e0;
	padding-bottom: 10px;
	padding-top: 10px;
}

.requirements {
	display: flex;
	flex-direction: column;
	margin: 10px;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f5;
	border-radius: 10px;
	padding: 10px 0px 10px 0px;
	font-size: small;
}

.requirements-list {
	display: flex;

	justify-content: center;

	border-radius: 10px;

	flex-wrap: wrap;
	gap: 7px;
}

.requirements-span {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	padding: 7px;
	border-radius: 10px;
}

.requirements-span svg {
	margin: 10px;
}

.statement-list-item {
	display: flex;
	font-size: x-small;
}

.statement-list-item b {
	display: block;
	margin-right: 10px;

	font-size: x-small;
}

.invalid {
	text-align: center;
	font-size: 1rem;
}

.mission-details svg {
	margin: 20px;
	color: black;
	font-size: 1.3rem;
}

.listboxes:hover {
	box-shadow: 0px 0px 3px 0px #cbc5bc;
}
.mapboxgl-canvas {
	height: 100% !important;
}

.close {
	display: flex;
	justify-content: flex-end;
	padding: 10px;
	color: #8c8c8c;
	position: absolute;
	right: 10px;
	top: 10px;
}

.close:hover {
	cursor: pointer;
	color: black;
}

.card-image {
	height: 100%;
	object-fit: cover;
}

.creditCard {
	color: #c0c0c0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
}

/* width */
::-webkit-scrollbar {
	width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #f2f2f2;
}

.intref {
	padding: 10px 10px;
	color: #8c8c8c;
}

.sticky {
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 1;
	opacity: 0.9;
}

.available {
	color: #000000;
	font-size: 10px;

	background-color: #f3f5f5;

	padding: 4px 7px;
	border-radius: 7px;
	width: 90px;
	user-select: none;
	-webkit-user-select: none;
}

.noselect {
	user-select: none;
	-webkit-user-select: none;
}

.immediate {
	color: #ffffff;
	font-size: 10px;
	background-color: firebrick;

	padding: 4px 7px;
	border-radius: 7px;
	width: 90px;
	margin-top: 5px;
	user-select: none;
	-webkit-user-select: none;
}

.hourly {
	color: #ffffff;
	font-size: 10px;
	background-color: rgb(18, 83, 85);

	padding: 4px 7px;
	border-radius: 7px;
	width: 90px;
	margin-top: 5px;
	user-select: none;
	-webkit-user-select: none;
}

.inTransit {
	color: #653325;
	font-size: 10px;

	background-color: orange;

	padding: 4px 7px;
	border-radius: 7px;
	width: 90px;
	user-select: none;
	-webkit-user-select: none;
}

.delivered {
	color: #3b3e3b;

	font-size: 10px;
	user-select: none;
	-webkit-user-select: none;
}

.paid {
	color: #3b3e3b;

	font-size: 10px;
	user-select: none;
	-webkit-user-select: none;
}

.booked {
	font-size: 10px;

	padding: 4px 7px;
	border-radius: 7px;
	width: 90px;
	user-select: none;
	-webkit-user-select: none;
}

.delivered {
	color: #000000;
	font-size: 10px;
	background-color: rgb(241, 255, 241);

	padding: 4px 7px;
	border-radius: 7px;
	width: 90px;
	user-select: none;
	-webkit-user-select: none;
}

.listicons {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.listicons svg {
	margin: 2px;
}

.price {
	font-size: 1rem;
	font-weight: bold;
	color: firebrick;
	justify-content: flex-start;
	user-select: none;
	-webkit-user-select: none;
}

.shortref {
	font-size: 0.7rem;
	font-weight: bold;
	color: #616161;

	padding: 7px;
}

.weight {
	font-size: 0.5rem;
	font-weight: bold;

	padding: 4px;
	border: 3px solid;
	border-radius: 7px;
	user-select: none;
	-webkit-user-select: none;
}

.mission-card {
	padding-top: 40px;
}

.css-19kzrtu {
	padding: 0px !important;
}

.no-missions {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
	flex-direction: column;

	color: rgb(189, 189, 189);
	padding: 10px;
	text-align: center;
	border: #e2e2e2 solid 1px;
	border-radius: 20px;
}

.city {
	color: #000000;
	text-decoration: underline;
}

.post-list-from-to {
	display: flex;
	flex-direction: row;
}

@media screen and (max-width: 650px) {
	body {
		font-size: small;
	}

	.post-list-from-to {
		font-size: 10px;
		flex-direction: column;
		align-items: flex-start;
		padding: 10px 0px;
	}
}

.language {
	border: 1px 1px 0px 0px solid #d7b7af;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	margin: 40px 0px;
	align-items: center;
	justify-content: center;
}
.language h3 {
	color: #d7b7af;
}

.language button {
	background-color: #ffd9d0;
	border: 1px solid #d7b7af;

	border-radius: 10px;
	padding: 10px;
	margin: 5px;
	color: #8c8c8c;
	font-size: 0.8rem;
	font-weight: bold;
}

.deliveryMode {
	padding-top: 10px;
}

.noconnect {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100dvh;
	text-align: center;

	color: #d7b7af;
	user-select: none;
	-webkit-user-select: none;
}

.cancel {
	background-color: #ffd9d0;
	border: 1px solid #d7b7af;

	border-radius: 10px;
	padding: 10px;
	margin: 5px;
	color: #8c8c8c;
	font-size: 0.8rem;
	font-weight: bold;
	display: flex;
	align-items: center;

	align-self: center;
	cursor: pointer;
}

.mission-details {
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;
	border: 1px solid #d7b7af80;
	border-radius: 20px;
	margin: 30px;
	width: calc(100vw-60px);
	background-color: white;
	box-shadow: #38342865 0px 0px 3px;
}

.mission-delivery-mode div {
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 30px;
	width: auto;
}

.mission-details-container {
	display: flex;
	flex-direction: column;

	flex-direction: column;
	align-items: center;
	justify-content: center;

	border-radius: 10px;

	width: 100vw;

	gap: 20px;
	padding: 10px;
	font-size: 12px;
}
.mission-details-container {
	padding: 4px;
}

.mission-details-container b {
	color: #5f5c52;
	font-size: 12px;
	font-weight: bold;
	text-align: left;
	width: 100px;
	display: inline-block;
}

.map {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 420px;
}
.mapa {
	width: 100%;
	height: 420px;
}

.deliver-time {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px;
	font-size: 12px;
	font-weight: bold;
}

.from-to {
	font-size: 12px;
	font-weight: bold;
}

.conn-isue {
	border: 1px solid #d7b7af56;
	padding: 50px;
	border-radius: 20px;
}

.mapboxgl-canvas {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.snapList {
	display: flex;
	justify-content: space-between;
	padding: 10px 0px;
	border-bottom: 1px solid #00000023;
}

.redbold {
	font-weight: bold !important;
	color: red;
	font-size: smaller !important;
}

.mapboxgl-ctrl-geocoder--input {
	width: 100% !important;
	max-width: 100% !important;
}

.mapboxgl-ctrl-geocoder {
	width: 100% !important;
	max-width: 100% !important;
}

.orderEasyForm {
	padding: 0px 30px 0px 30px;
}

.close_bn {
	display: flex;
	justify-content: flex-end;
	padding: 20px 0px 0px 0px;
}

.close_bn a:hover {
	background-color: #a9b9a4;
}

@keyframes learn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.papoi {
	animation-name: learn;
	animation-duration: 6s;
}

.popoiOff {
	display: none;
}

.buttons:hover {
	border-color: #000;
}
.panel {
	width: 100%;
	height: 100px;
	border: #000 1px dotted;
	padding: 5px;
}

.geo-name {
	font-size: 12px;
	padding: 10px;
}
